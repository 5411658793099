import Loading from "./../../components/loading";
import device from "current-device";
import QRCode from 'qrcodejs2'
import { showErrorMsg } from "@/helpers/ajax";

export default {
  name: "login",
  components: {
    Loading,
    device,
  },
  data() {
    return {
      formData: {
        loginName: "", // 用户名 wjlcszx01
        password: "" // 密码 Tmva123!!
      },
      showErrorMsg: "",
      lang: this.$utils.getLocalStorage("lang") || "zh",
      showLoading: false,
      showPage: false,
      loadingFail: false,
      isSavePass: true,
      IsAutoLogin: false,
      referUrl: this.$utils.getLocalStorage("referUrl"),
      showPass: false,
      fileDownloadUrl: "",
      percentComplete: 0,
      isSHowPercent: false,
      apkDownloadDialogVisible: false,
      apkQrcode: null,
      downApkUrl: null,
    };
  },
  created() {
    // 请求头
    this.$utils.setSessionStorage("DeviceType", "WEB") // 设备类型
    this.$utils.setSessionStorage("SoftwareVersion", "SEATS") // 软件类型
    this.$utils.setSessionStorage("version", "TMVA_1_8") // 软件版本
    this.$utils.setSessionStorage("OemName", "TMVA") // OEM版本（主版本为TMVA）

    const { token, language } = this.$route.query

    if (!token) {
      if (this.$utils.getSessionStorage("loginToken")) {
        this.loadingFail = true
      } else {
        this.showPage = true
      }
      return
    }
    this.showLoading = true
    const lang = language === "EN" ? "en" : "zh"
    this.$i18n.locale = lang
    this.$utils.setLocalStorage("lang", lang)
    this.$utils.setSessionStorage("loginToken", token)
    console.log('created')
    this.webTokenLogin(token);
  },
  mounted() {
    console.log('mounted', this)

    this.getCookie()
    this.getAutoLogin()
  },
  methods: {
    apkDownloadClose() {
      this.$refs.apkDownload.innerHTML = ''
    },
    apkDownload() {
      this.$ajax({
        url: this.$api.checkForUpdat,
        data: {
          osType: "APK",
          category: "TMVA"
        },
      }).then(res => {
        if (res.master) {
          this.apkDownloadDialogVisible = true
          this.downApkUrl = res.master.downloadUrl
          this.$nextTick(() => {
            this.apkQrcode = new QRCode(this.$refs.apkDownload, {
              width: 200, // 设置宽度，单位像素
              height: 200, // 设置高度，单位像素
              text: res.master.downloadUrl, // 设置二维码内容或跳转地址
              correctLevel: QRCode.CorrectLevel.H
            })
          })
        }
      })
    },
    getfileDownload() {
      const that = this
      that.$ajax({
        url: this.$api.checkForUpdat,
        data: {
          osType: "APK",
          category: "TMVA"
        },
      }).then(data => {
        const { downloadUrl, title } = data.master
        var a = document.createElement('a');
        a.href = downloadUrl
        a.download = title;
        a.click()
      })
    },
    // 第三方静默登录
    webTokenLogin(token) {
      this.$ajax({
        url: this.$api.webTokenLogin,
        data: {
          loginName: token
        }
      })
        .then(data => {
          this.$utils.setSessionStorage("token", data.token);
          this.$store.commit("updateUser", data);
          this.$router.replace({ path: "/" });
        })
        .catch(() => {
          this.showLoading = false;
          this.loadingFail = true;
        });
    },
    // 选择语言
    changeLang(lang) {
      this.lang = lang;
      this.$i18n.locale = lang;
      this.$utils.setLocalStorage("lang", lang);
    },
    // 输入内容发生改变
    changValue() {
      this.showErrorMsg = "";
    },
    // 用户登录
    userLogin() {
      const { loginName, password } = this.formData;
      if (!loginName.trim()) {
        this.$utils.showMsg(this.$t('accountNoEmpty'), "warning");
        return;
      }
      if (!password.trim()) {
        this.$utils.showMsg(this.$t('passwordNoEmpty'), "warning");
        return;
      }
      if (
        !this.$validate(this.formData, [
          {
            label: this.$t("account"),
            prop: "loginName",
            noNull: true
          },
          {
            label: this.$t("password"),
            prop: "password",
            noNull: true
          }
        ])
      ) {
        return;
      }
      this.$ajax({
        url: this.$api.userAccountLogin,
        data: this.formData,
        defineError: true
      }).then(data => {
        console.log(data)
        // return
        if (data.state === 'SUCCESS') {
          data = data.data
          this.$utils.setSessionStorage("token", data.token);
          // 保存密码
          if (this.isSavePass) {
            this.$utils.setLocalStorage("loginName", this.formData.loginName)
            this.$utils.setLocalStorage("passWord", this.formData.password)
          } else {
            this.$utils.deleteLocalStorage("loginName")
            this.$utils.deleteLocalStorage("passWord")
            this.isSavePass = false
          }
          // 下次是否自动登录
          if (this.IsAutoLogin) {
            this.$utils.setLocalStorage("AutoLogin", true)
          } else {
            this.$utils.deleteLocalStorage("AutoLogin", false)
          }

          this.$store.commit("updateUser", data);
          if (this.referUrl) {
            this.$router.replace({ path: this.referUrl })
          } else {
            this.$router.replace({ path: "/" })
          }
        } else {
          const errCode = data.msg === 'DATA_000002' ? 'ACCOUNT_000001' : data.msg
          showErrorMsg(this.$t(errCode))
        }
        // this.$router.replace({ path: "/" })
      }).catch(() => {
        this.$utils.setonresize("el-message-box__wrapper")
      });
    },
    // 读取cookie 将用户名和密码回显到input框中
    getCookie() {
      const name = this.$utils.getLocalStorage("loginName")
      const pass = this.$utils.getLocalStorage("passWord")
      if (name) {
        this.formData.loginName = name
        this.formData.password = pass
      }
    },
    getAutoLogin() {
      console.log(this.$utils.getLocalStorage("AutoLogin"))
      if (this.$utils.getLocalStorage("AutoLogin")) {
        this.IsAutoLogin = true
        const name = this.$utils.getLocalStorage("loginName")
        const pass = this.$utils.getLocalStorage("passWord")
        if (name) {
          this.formData.loginName = name
          this.formData.password = pass
        }
        this.formData = this.$utils.copyData(this.formData)
        console.log(this.formData)
        setTimeout(() => {
          this.userLogin()
        }, 200)
      }
    },
  }
};
